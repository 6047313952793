<template>
  <div class="container py-5">
    <p class="fw-bold">Workshop at AMWC 2023</p>
    <p>本次Workshop特邀四位醫生針對OBSERV全新技術及其應用進行分享，敬請撥冗參與。</p>
    <p>
        <b>Workshop</b><br>
        時間：05/06 11:30-12:30<br>
        地點：201D (Level 2)<br><br>

        <b>Moderator：許哲豪醫師 / Che-Hao HSU, MD</b><br>

        <img
          class="img-md rounded"
          src="@/assets/images/zh-TW/news/new_22_1.jpg"
        /><br><br><br> 
        
        <b>Speaker：張長正醫師 / Chang-Cheng Chang, MD., Ph.D., FACS</b><br>
        Topic：The composition of anti-aging scales for facial rejuvenation – assessed with Artificial Intelligence.<br>

        <img
          class="img-md rounded"
          src="@/assets/images/zh-TW/news/new_22_2.jpg"
        /><br><br><br> 
        
        <b>Speaker：黃昭瑜醫師 / Charlene CY Ng, MD., Ph.D</b><br>
        Topic：Precision medicine in diagnosing pigmentary disorders: A comparative study and validation of advanced skin imaging system.<br>

        <img
          class="img-md rounded"
          src="@/assets/images/zh-TW/news/new_22_4.jpg"
        /><br><br><br>

         <b>Speaker：黃輝鵬醫師 / Hui-Peng Huang, MD</b><br> 
        Topic：Erythema assessment for rosacea-comparison between OBSERV 520x system and photography.<br> 

        <img
          class="img-md rounded"
          src="@/assets/images/zh-TW/news/new_22_3.jpg"
        /><br><br>
    </p>

  </div>
</template>
<script>
export default {};
</script>
